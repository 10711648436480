import React, {useEffect, useState} from "react";
import {FetchJsonAsync} from "../helper/Helper";
import {useIntl} from 'react-intl'

function News() {
  const [data, setData] = useState([]);
  const intl = useIntl();
  const key1 = intl.formatMessage({id: "settings.key1"});
  const key2 = intl.formatMessage({id: "settings.key2"});
  const key3 = intl.formatMessage({id: "settings.key3"});
  const key4 = intl.formatMessage({id: "settings.key4"});
  const key5 = intl.formatMessage({id: "settings.key5"});
  
  useEffect(() => {
    try {
      const func = async () => {
        const tmp1 = await FetchJsonAsync('https://rr-457.de/pwa/news', false, true, key1, localStorage.getItem("key1") === "true");
        const tmp2 = await FetchJsonAsync('https://rr-457.de/pwa/news/forscher', false, true, key2, localStorage.getItem("key2") === "true");
        const tmp3 = await FetchJsonAsync('https://rr-457.de/pwa/news/kundschafter', false, true, key3, localStorage.getItem("key3") === "true");
        const tmp4 = await FetchJsonAsync('https://rr-457.de/pwa/news/pfadfinder-m', false, true, key4, localStorage.getItem("key4") === "true");
        const tmp5 = await FetchJsonAsync('https://rr-457.de/pwa/news/pfadfinder-w', false, true, key5, localStorage.getItem("key5") === "true");

        setData((tmp1.concat(tmp2).concat(tmp3).concat(tmp4).concat(tmp5)).sort((a, b) => a.id < b.id ? 1 : -1));
        console.log("Fetch data!");
      };
      func().then(_ =>{}).catch(console.error);
    } catch (error) {
      console.error(error);
    }
  }, [key1, key2, key3, key4, key5]);

  return (
  <div className='page'>
      {data.map((item) => (
            <div className="card news-item" key={item.id} style={{'maxWidth': '30rem', 'width': '100%'}}>
              {item.img[0] !== undefined &&
                  <img src={item.img[0]} className="card-img-top" alt={item.title}/>
              }
              <div className="card-body">
                {item.title !== undefined &&
                  <h5 className="card-title">{item.title}</h5>
                }
                <p className="card-text" dangerouslySetInnerHTML={{ __html: item.content}} />
              </div>
              <div className="card-footer">
                <span className="badge bg-secondary">{item.label}</span>
              </div>
            </div>
      ))}
  </div>
  );
}

export default News;
