import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { FetchJson } from "../helper/Helper";
import {useIntl} from 'react-intl'

function Item() {
    const [data, setData] = useState([]);
    const params = useParams();
    const element = "" + params.element;
    const msg = useIntl().formatMessage({id: "sys.error"});

    useEffect(() => {
      try {
        FetchJson('https://rr-457.de/pwa/neuigkeiten/' + element, setData);
      } catch(error) {
        setData(msg);
        console.error(error);
      }
    }, [element, msg])

    if (data === undefined) return;

    return (
        <div className="page">
            <h1>News {element}</h1>
            <div dangerouslySetInnerHTML={{ __html: data }} />
        </div>
    );
}

export default Item;
