import React, {useState, useRef} from "react";
import emailjs from '@emailjs/browser';
import {FormattedMessage} from 'react-intl'

function Mail() {
  const [data, setData] = useState("");
  const form = useRef();

  const handleSubmit = (event) => {
    try {
      event.preventDefault()
      const serviceId = process.env.REACT_APP_SMTP_SERVICE_ID;
      const templateId = process.env.REACT_APP_SMTP_TEMPLATE_ID;
      const publicKey = process.env.REACT_APP_SMTP_PUBLIC_KEY;

      emailjs.sendForm(serviceId, templateId, form.current, {
        publicKey: publicKey,
      })
        .then((result) => {
          setData("<div class='alert alert-info' role='alert'>" + result.text + "</div>");
        }, (error) => {
          setData("<div class='alert alert-danger' role='alert'>" + error.text + "</div>");
        });
    } catch(error) {
      setData("<div class='alert alert-danger' role='alert'>" + error.toString() + "</div>");
      console.error(error);
    }
  }

  return (
    <div className="page" id="contact">
      <h1><FormattedMessage id="email.title" defaultMessage=""/></h1>
      <form ref={form} onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="email" className="form-label">
            <FormattedMessage id="email.mail" defaultMessage=""/>
          </label>
          <input type="email" className="form-control" id="email" name="email"/>
        </div>
        <div className="mb-3">
          <label htmlFor="subject" className="form-label">
            <FormattedMessage id="email.subject" defaultMessage=""/>
          </label>
          <input type="text" className="form-control" id="subject" name="subject"/>
        </div>
        <div className="mb-3">
          <label htmlFor="content" className="form-label">
            <FormattedMessage id="email.content" defaultMessage=""/>
          </label>
          <textarea className="form-control" id="content" name="content" rows="10"/>
        </div>
        <input type="submit" value="Absenden" />
        <div dangerouslySetInnerHTML={{ __html: data }} style={{'margin':'1em'}} />
      </form>
    </div>
  );
}

export default Mail;
