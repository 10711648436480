import React, {useEffect, useState} from "react";
import {FormattedMessage} from 'react-intl'

const key1 = "key1";
const key2 = "key2";
const key3 = "key3";
const key4 = "key4";
const key5 = "key5";

function Settings() {
    const [data, setData] = useState("");
    const [value1, setValue1] = useState(true);
    const [value2, setValue2] = useState(true);
    const [value3, setValue3] = useState(true);
    const [value4, setValue4] = useState(true);
    const [value5, setValue5] = useState(true);

    useEffect(() => {
        try {
            setValue1(localStorage.getItem(key1) === "true");
            setValue2(localStorage.getItem(key2) === "true");
            setValue3(localStorage.getItem(key3) === "true");
            setValue4(localStorage.getItem(key4) === "true");
            setValue5(localStorage.getItem(key5) === "true");
        } catch(error) {
            setData(error);
        }
    }, [])

    const handleSubmit = (event) => {
        try {
            event.preventDefault();
            setValue1(event.target[key1].value);
            setValue2(event.target[key2].value);
            setValue3(event.target[key3].value);
            setValue4(event.target[key4].value);
            setValue5(event.target[key5].value);

            localStorage.setItem(key1, value1 ? "true" : "false");
            localStorage.setItem(key2, value2 ? "true" : "false");
            localStorage.setItem(key3, value3 ? "true" : "false");
            localStorage.setItem(key4, value4 ? "true" : "false");
            localStorage.setItem(key5, value5 ? "true" : "false");
        } catch (error) {
            setData("<div class='alert alert-danger' role='alert'>" + error.toString() + "</div>");
            console.error(error);
        }
    };

    const onChange = (event) => {
        if(event.target.name === key1) {
            setValue1(!value1);
        }
        if(event.target.name === key2) {
            setValue2(!value2);
        }
        if(event.target.name === key3) {
            setValue3(!value3);
        }
        if(event.target.name === key4) {
            setValue4(!value4);
        }
        if(event.target.name === key5) {
            setValue5(!value5);
        }
    };

    return (
        <div className="page">
            <h1><FormattedMessage id="settings.title"/></h1>
            <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                    <div className="input-group-text">
                        <input className="form-check-input mt-0" type="checkbox" name={key1} id={key1} checked={value1} onChange={onChange}/>
                    </div>
                    <label htmlFor={key1} className="form-control">
                        <FormattedMessage id="settings.key1" defaultMessage=""/>
                    </label>
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-text">
                        <input className="form-check-input mt-0" type="checkbox" name={key2} id={key2} checked={value2} onChange={onChange}/>
                    </div>
                    <label htmlFor={key2} className="form-control">
                        <FormattedMessage id="settings.key2" defaultMessage=""/>
                    </label>
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-text">
                        <input className="form-check-input mt-0" type="checkbox" name={key3} id={key3} checked={value3} onChange={onChange}/>
                    </div>
                    <label htmlFor={key3} className="form-control">
                        <FormattedMessage id="settings.key3" defaultMessage=""/>
                    </label>
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-text">
                        <input className="form-check-input mt-0" type="checkbox" name={key4} id={key4} checked={value4} onChange={onChange}/>
                    </div>
                    <label htmlFor={key4} className="form-control">
                        <FormattedMessage id="settings.key4" defaultMessage=""/>
                    </label>
                </div>
                <div className="input-group mb-3">
                    <div className="input-group-text">
                        <input className="form-check-input mt-0" type="checkbox" name={key5} id={key5} checked={value5} onChange={onChange}/>
                    </div>
                    <label htmlFor={key5} className="form-control">
                        <FormattedMessage id="settings.key5" defaultMessage=""/>
                    </label>
                </div>
                <input type="submit" value="Absenden" className="btn btn-outline-secondary"/>
                <div dangerouslySetInnerHTML={{__html: data}} style={{'margin': '1em'}}/>
            </form>
        </div>
    );
}

export default Settings;