import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {IntlProvider} from 'react-intl';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import RR457 from "./pages/RR457";
import News from "./pages/News";
import Calendar from "./pages/Calendar";
import Item from "./pages/Item";
import Mail from "./pages/Mail";
import Settings from "./pages/Settings";
import German from './lang/de.json';

const locale = navigator.language;

const root = ReactDOM.createRoot(
    document.getElementById('root')
);
root.render(
    <IntlProvider locale={locale} messages={German}>
        <App />
    </IntlProvider>
);

export default function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Layout />}>
            <Route index element={<RR457 />} />
            <Route path="news" element={<News />} />
            <Route path="calendar" element={<Calendar />} />
            <Route path='item/:element' element={<Item />}/>
            <Route path='mail' element={<Mail />}/>
            <Route path="settings" element={<Settings />}/>
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
