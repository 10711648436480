import { Outlet, NavLink } from "react-router-dom";
import React from 'react';
import news from './../img/news.png';
import mail from './../img/contact.png';
import rr457 from './../img/rr457.jpg';
import calendar from './../img/calendar.png';
import settings from "./../img/settings.png";

const Layout = () => {
  return (
      <div className="container-fluid">
        <nav>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <NavLink className="nav-link" to="/">
                <img src={rr457} alt="RR457-Page" className="tab-icon"/>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/news">
                <img src={news} alt="News-Page" className="tab-icon"/>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/calendar">
                <img src={calendar} alt="Calendar-Page" className="tab-icon"/>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/mail">
                <img src={mail} alt="Mail-Page" className="tab-icon"/>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/settings">
                <img src={settings} alt="Settings-Page" className="tab-icon"/>
              </NavLink>
            </li>
          </ul>
        </nav>
        <Outlet/>
      </div>
  )
};

export default Layout;
