
export function FetchJson(url, setData, toObject = false, structured = false, label = "", enabled = true ) {
    if(enabled) {
        fetch(url, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json'
            },
            next: {revalidate: 30}
        }).then(function(response) {
            if(response.ok) {
                return response.json();
            }
        }).then(function(json) {
            const content = json.content;
            if(Array.isArray(content.colPos0)) {
                if(content.colPos0.size !== 0) {
                    if(structured) {
                        var pos0 = content.colPos0;
                        var contentArray;
                        var index = 0;
                        contentArray = pos0.map((item) => {

                            var images = [];
                            try {
                                var columns = item.content.gallery.count.columns;
                                var rows = item.content.gallery.count.rows;

                                for(var row = 1; row<=rows; row++) {
                                    for(var column = 1; column<=columns; column++) {
                                        images.push(item.content.gallery.rows[row.toString()].columns[column.toString()].publicUrl);
                                    }
                                }
                            } catch(error) {
                                console.error(error);
                            }
                            index++;

                            return {
                                key: index,
                                id: item.id,
                                title: item.content.header,
                                content: item.content.bodytext,
                                label: label,
                                img: images
                            }
                        });
                        setData(contentArray);
                    } else {
                        var data = content.colPos0[0].content.data.toString()
                            .replaceAll('href="/', 'href="https://rr-457.de/')
                            .replaceAll('src="/', 'src="https://rr-457.de/');
                        if(toObject) {
                            setData(ParseHTMLString(data));
                        } else {
                            setData(data);
                        }
                    }
                } else {
                    setData([]);
                }
            }
        });
    } else {
        setData([]);
    }
}

export async function FetchJsonAsync(url, toObject = false, structured = false, label = "", enabled = true ) {
    if(enabled) {
        const response = await fetch(url, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json'
            },
            next: {revalidate: 30}
        });
        if(response.ok) {
            const json = await response.json();
            const content = json.content;
            if(Array.isArray(content.colPos0)) {
                if(content.colPos0.size !== 0) {
                    if(structured) {
                        var pos0 = content.colPos0;
                        var contentArray;
                        var index = 0;
                        contentArray = pos0.map((item) => {

                            var images = [];
                            try {
                                var columns = item.content.gallery.count.columns;
                                var rows = item.content.gallery.count.rows;

                                for(var row = 1; row<=rows; row++) {
                                    for(var column = 1; column<=columns; column++) {
                                        images.push(item.content.gallery.rows[row.toString()].columns[column.toString()].publicUrl);
                                    }
                                }
                            } catch(error) {
                                console.error(error);
                            }
                            index++;

                            return {
                                key: index,
                                id: item.id,
                                title: item.content.header,
                                content: item.content.bodytext,
                                label: label,
                                img: images
                            }
                        });
                        return await contentArray;
                    } else {
                        var data = content.colPos0[0].content.data.toString()
                            .replaceAll('href="/', 'href="https://rr-457.de/')
                            .replaceAll('src="/', 'src="https://rr-457.de/');
                        if(toObject) {
                            return ParseHTMLString(data);
                        } else {
                            return await data;
                        }
                    }
                } else {
                    return "";
                }
            }
        } else {
            return response.statusText;
        }
    } else {
        return [];
    }
}

export function ParseHTMLString(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const items = Array.from(doc.querySelectorAll('.list-group-item'));
  
    var key = 0;
    return items.map(item => (  
    {
      key: key++,
      img: item.querySelector("img.img-fluid").src || '',
      time: item.querySelector("time")?.textContent || '',
      title: item.querySelector('span.label.label-info')?.textContent || '',
      description: item.querySelector('div.lead')?.textContent || '',
      link: getLast(item.querySelector("a.btn.btn-primary.btn-read-more")?.href.toString()) || '',
    }));
}


function getLast(item) {
    return item.toString().slice(item.toString().lastIndexOf("/") + 1, item.toString().length);
}