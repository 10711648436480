import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import { FetchJson } from "../helper/Helper";
import {FormattedMessage, useIntl} from 'react-intl'

function Calendar() {
    const [data, setData] = useState([]);
    const msg = useIntl().formatMessage({id: "sys.error"});

    useEffect(() => {
      try {
        FetchJson('https://rr-457.de/pwa/neuigkeiten', setData, true);

      } catch(error) {
        setData({
          key: 99, img: '', time: '', title: msg,
          description: error.toString(), link: '',
        });
        console.error(error);
      }
    }, [msg])

    if (data === undefined) return;

    return (
        <div className="page">
            <h1><FormattedMessage id="calendar.title" defaultMessage=""/></h1>
            {data.map((item) => (
              <div className="card news-item" key={item.key} style={{'maxWidth': '30rem', 'width': '100%'}}>
                <img src={item.img} className="card-img-top" alt={item.title}/>
                <div className="card-body">
                  <h5 className="card-title">{item.title}</h5>
                  <p className="card-text">{item.description}</p>
                </div>
                <div className="card-footer">
                  <p className="card-text">{item.time}</p>
                  <NavLink to={"/item/" + item.link} className='btn btn-outline-secondary'>
                      <FormattedMessage id="calendar.further" defaultMessage=""/>
                  </NavLink>
                </div>
              </div>
            ))}
        </div>
    );
}

export default Calendar;