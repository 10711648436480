import React, {useEffect, useState} from "react";
import { FetchJson } from "../helper/Helper";
import {FormattedMessage} from 'react-intl';

function RR457() {
    const [data, setData] = useState([]);

    useEffect(() => {
        try {
          FetchJson('https://rr-457.de/pwa/rr-457', setData, false, true);
        } catch(error) {
          console.error(error);
        }
      }, []);

    return (
    <div className='page'>
        <h1><FormattedMessage id="rr.title" defaultMessage=""/></h1>
        {data.map((item) => (
              <div className="card news-item" key={item.key} style={{'maxWidth': '30rem', 'width': '100%'}}>
                {item.img[0] !== undefined &&
                    <img src={item.img[0]} className="card-img-top" alt={item.title}/>
                }
                <div className="card-body">
                  {item.title !== undefined &&
                    <h5 className="card-title">{item.title}</h5>
                  }
                  <p className="card-text" dangerouslySetInnerHTML={{ __html: item.content}} />
                </div>
              </div>
            ))}
    </div>
    );
}

export default RR457;
